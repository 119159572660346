<template>
  <div v-if="results.length > 0 && color !== 'white'" class="d-flex align-items-center code-row">
      <div class="d-inline-block cell" :class="color"></div>
      <div class="d-inline-block codes">
        <div v-for="code in results" :key="'code-' + code" class="code mr-3 d-inline-block">{{ code }}</div>
      </div>
  </div>
</template>

<script>

export default {
  props: ['color', 'codesList'],
  computed: {
    results() {
      return this.codesList.filter(code => code.value === this.color).map(code => code.id)
    }
  }
}
</script>

<style scoped>

.code-row {
  margin-bottom: 10px;
}

.cell {
  margin-right: 30px;
}

.codes {
  text-align: left;
}

.code {
  width: 30px;
}
</style>