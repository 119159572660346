<template>
  <div id="app">
    <div class="container">

      <form class="row">
        <div class="col-md-5">
          <label class="form-label float-left">Szerokość tablicy</label>
          <input type="number" step="2" :max="maxSize" class="form-control" v-model.number="width">
        </div>
        <div class="col-md-5">
          <label class="form-label  float-left">Wysokość tablicy</label>
          <input type="number" step="2" :max="maxSize" class="form-control" v-model.number="height">
        </div>
        <div class="col-md-2">
          <button class="btn btn-primary generate" @click.prevent="resetTable">Resetuj tablicę</button>
        </div>
      </form>

      <div class="form-check form-check-inline mt-3 row justify-content-center">
        <div v-for="color in colors" :key="color" class="mr-2">
          <input type="radio" v-model="fillColor" class="btn-check" name="options" :id="color" :value="color" autocomplete="off" />
          <label class="btn btn-primary color-picker" :class="color" :for="color"><span v-if="color === fillColor" class="selected-label">{{ color }}</span></label>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-8 col-md-12">
          <table>
            <tr>
              <th></th>
              <th v-for="h in height" :key="columnLabels[h] + 'width2'">{{columnLabels[h - 1]}}</th>
            </tr>
            <tr v-for="w in width" :key="w + 'height'">
              <th>{{w}}</th>
              <td v-for="h in height" :class="{'vertical-border': (h === height / 2) && showDiameters, 'horizontal-border': (w === width / 2) && showDiameters}" :key="h + 'width'" @click="toggleClass" :id="columnLabels[h - 1] + '' + w"></td>
            </tr>
          </table>
        </div>

        <div class="col-lg-4 col-md-12">

          <div class="form-check mt-3">
            <input type="checkbox" class="form-check-input" v-model="showDiameters" id="diameters">
            <label class="form-check-label" for="diameters">Pokaż średnice dla parzystej wielkości tablicy</label>
          </div>

          <div class="codes-block">
            <button class="btn btn-primary" @click="generateCodes">Generuj kody</button>
            <div class="codes mt-3">
                <code-row v-for="color in colors" :key="'code-color-' + color" :color="color" :codes-list="codesList"></code-row>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import CodeRow from './components/CodeRow'

export default {
  name: 'App',
  components: { CodeRow },
  data() {
    return {
      width: 10,
      height: 10,
      maxSize: 16,
      columnLabels: 'ABCDEFGHIJKLMNOPRSTU'.split(''),
      showDiameters: true,
      fillColor: 'blue',
      colors: ['pink', 'purple', 'blue', 'green', 'yellow', 'orange', 'red', 'brown', 'black', 'white'],
      codesList: []
    }
  },
  methods: {
    toggleClass(el) {
      el.target.classList = [this.fillColor]
    },
    generateCodes() {
      this.codesList = []
      const arr = [ ...document.getElementsByTagName('td') ]
      arr.forEach(cell => {
        if(cell.classList.length > 0) {
          this.codesList.push({
            id: cell.id,
            value: cell.classList[0]
          })
        }
      })
    },
    resetTable() {
      this.width = 10
      this.height = 10
      this.codesList = []
      const tableCells = [...document.getElementsByTagName('td')]
      tableCells.forEach(td => td.classList = [])
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

  button.generate {
    margin-top: 32px;
  }

  .cell {
    min-width: 40px;
    height: 40px;
    border: #2c3e50 1px solid;
  }

  th {
    width: 43px;
  }

  td {
    width: 43px;
    height: 43px;
    border: #2c3e50 1px solid;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked+label {
    font-weight: bold;
  }

  .color-picker {
    width: 80px;
    height: 30px;
    border-color: slategray !important;
    /*box-shadow: #2c3e50 5px 5px;*/
  }


  .pink {
    /*background-color: hotpink !important;*/
    background: linear-gradient(to top right, #ff0081, #f8879d) !important;
  }

  .purple {
    /*background-color: blueviolet !important;*/
    background: linear-gradient(to top right, #49018e, #953aea) !important;
  }

  .blue {
    /*background-color: #2020b3 !important;*/
    background: linear-gradient(to top right, #0f0f79, #4545f5) !important;
  }

  .green {
    /*background-color: green !important;*/
    background: linear-gradient(to top right, #006400, #8be311) !important;
  }

  .yellow {
    background: linear-gradient(to top right, #ffa200, #FFFF52) !important;
  }

  .orange {
    /*background-color: orange !important;*/
    background: linear-gradient(to top right, #ec4000, orange) !important;
  }

  .red {
    /*background-color: #ff0000 !important;*/
    /*background: linear-gradient(to top right, #ff0000, #ec5e5e) !important;*/
    background: linear-gradient(to top right, #800000, #f13636) !important;
  }

  .brown {
    /*background-color: #800000 !important;*/
    /*background: linear-gradient(to top right, #800000, #f13636) !important;*/
    background: linear-gradient(to top right, #500000, #a32a2a) !important;
  }

  .black {
    /*background-color: black !important;*/
    background: linear-gradient(to top right, #000000, #616161) !important;
  }

  .white {
    background-color: white !important;
  }

  .horizontal-border {
    border-bottom: 2px solid red;
  }

  .vertical-border {
    border-right: 2px solid red;
  }

  .selected-label {
    position: relative;
    color: white;
    text-shadow: 1px 1px black;
    bottom: 4px;
  }

  .codes-block {
    padding-top: 25px;
  }

  .form-check-inline {
    margin-right: 0 !important;
    padding-left: 1.25rem !important;
  }

  @media(min-width: 1400px) {
    .container {
      min-width: 1400px;
    }
  }

  @media (min-width: 765px) {
    th {
      width: 55px;
    }
    td {
      width: 55px;
      height: 55px;
    }
  }
</style>
